import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

const drawerWidth = 175;

const useStyles = makeStyles(theme => ({
  root: {display: 'flex'},
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {width: drawerWidth},
  docked: {
    backgroundColor: 'orange',
  },
  list: {
    width: 'auto',
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'black',
    padding: theme.spacing(3)
  }
}));

function SideCar() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  let buildArray = (number) => {
    let docs = new Array(number)
    let start = 0;
    for(let i = start; i <= number; i++) {
      let id = "Deed_" + i
      docs.push(id);
    }
    return docs
  }

  const docs = buildArray(580);

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List style={{backgroundColor: "black"}}>
        {docs.map((text, index) => (
          <ListItem button key={text} style={{color: "white"}}>
            <ListItemIcon>{index % 2 === 0 ? <CancelIcon color='error'/> : <CheckIcon style={{ color: green[500] }}/>}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))
      }
    </List>
    </div>
  );

  return (
    <div>

        <Button onClick={toggleDrawer('left', true)}>Corpus</Button>
        <Drawer open={state.left} onClose={toggleDrawer('left', false)}>{sideList('left')}</Drawer>

      {/*<Drawer className={classes.drawer} variant="permanent" classes={{paper: classes.drawerPaper}} anchor="left">{sideList('left')}</Drawer>*/}
    </div>
  );
}

export default SideCar;
