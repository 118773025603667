import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNode, faGoogle, faNpm, faPython, faReact } from '@fortawesome/free-brands-svg-icons';
import { DiMongodb } from "react-icons/di";
// import { ReactComponent as Logo } from '../../assets/MongoDBlogo.svg';
// Can use as normal Component like: <Logo />

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f50057",
    position: 'absolute',
    bottom: "0",
    width: "100%",
    flexShrink: "0",
    maxHeight: "2.5vh",
    marginBottom: "0px",
  },
  icons: {
    color: "#f50057",
    paddingLeft: ".5%",
    paddingRight: ".5%"
  }
})

function Footer(props) {
  const classes = useStyles();

  return (
      <footer className={classes.root}>
        <div style={{textAlign: "center"}}>ZIDUALZ LLC</div>
        <div style={{textAlign: "center", verticalAlign: "top", fontSize: "2.50em"}}>
          <FontAwesomeIcon className={classes.icons} icon={['fab', 'node']}/>
          <FontAwesomeIcon className={classes.icons} icon={['fab', 'google']}/>
          <FontAwesomeIcon className={classes.icons} icon={['fab', 'npm']}/>
          <FontAwesomeIcon className={classes.icons} icon={['fab', 'python']}/>
          <FontAwesomeIcon className={classes.icons} icon={['fab',  'react']}/>
          <DiMongodb className={classes.icons}/>
        </div>
      </footer>
  );
}

export default Footer;
/*
<FontAwesomeIcon icon={faGoogle} style={{color: "blue"}}/>
<FontAwesomeIcon icon={faNpm} style={{color: "blue"}}/>
<FontAwesomeIcon icon={faPython} style={{color: "blue"}}/>
*/
