import React, { Component } from 'react';
import Image from 'react-bootstrap/Image'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

function BoxImage(props) {

  return (
    <Card style={{marginBottom: "5%"}}>
      <CardMedia title="Source Image" image=<Image src={props.image} fluid style={{paddingBottom: "5%"}}/>/>
    </Card>
  )
}

export default BoxImage;
