import React from 'react';
import {ButtonToolbar, ButtonGroup} from 'react-bootstrap';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
      padding: "0.50%",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const ButtonToolBar = (props) => {

  const classes = useStyles();

  return(
        <Typography component="div" className={classes.root}>
          <ButtonToolbar>
            <ButtonGroup className="mr-2" aria-label="First Group">
              <Button id="previous" onClick={props.prev} variant="contained" className={classes.button} startIcon={<FaArrowAltCircleLeft/>}>Next</Button>
              <Button id="next" onClick={props.clicker} variant="contained" className={classes.button} startIcon={<FaArrowAltCircleRight/>}>Next</Button>
              <Button variant="contained" className={classes.button} startIcon={<EditIcon />} color="primary" aria-label="edit" onClick={props.handler}>{!props.editable ? 'EDIT' : 'SAVE'}</Button>
              <Button variant="contained" color="secondary" className={classes.button} startIcon={<DeleteIcon />}>Delete</Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Typography>
      )
    }

export default ButtonToolBar;
