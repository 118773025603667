import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Top from '../NAVBAR/Top';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  jumbo: {
    backgroundImage: "url('https://storage.googleapis.com/z_static/Images/Zidualz_Logo.png')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    backgroundColor: "white",
    backgroundSize: "contain",
    margin: "0"
  },
  typo: {
    color: "#ffffff",
    backgroundColor: "#f50057",
    width: "auto",
    fontWeight: "bold",
    paddingLeft: "2%",
    fontFamily: "Audiowide"
  },
  gridz: {
    padding: theme.spacing(3),
    flexGrow: 1,
    textAlign: 'center',
  },
  media: {
    height: "5%",
    paddingTop: '10.0%',
    paddingBottom: '2.0%',
  },
  card: {
    backgroundRepeat: "no-repeat",
    margin: "0",
    maxWidth: "10%",
    maxHeight: "5%",
  }
}));


const Header = (props) => {
  const classes = useStyles();
    return(
      <Typography component="div" style={props.style}>
          <Grid item={true}>
            <Card className={classes.card}>
              <CardMedia className={classes.media} image={'https://storage.googleapis.com/z_static/Images/Zidualz_Logo.png'}/>
            </Card>
          </Grid>
          <Grid offset={0}> {props.authenticated ? (<Top authenticated={props.authenticated} logOut={props.logOut}/>) : null} </Grid>
        <Typography className={classes.typo} variant="subtitle2">Zidualz Corpus Annotator</Typography>
        <Divider />
      </Typography>
    )
  }

export default Header;


//{<Grid container zeroMinWidth className={classes.gridz}>
  //<Jumbotron className={classes.jumbo}>
  //  <Container> {props.authenticated ? (<Top authenticated={props.authenticated} logOut={props.logOut}/>) : null} </Container>
  //</Jumbotron>
//</Grid>}
//backgroundImage={"url('https://storage.googleapis.com/z_static/Images/Zidualz_Logo.png')"}
