import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import Divider from '@material-ui/core/Divider';
//import {firebaseApp} from '../../firebase-config.js';

const Top = (props) => {
  return (
    <Navbar style={{backgroundColor: "white"}} expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav justify className="mr-auto">
          {/*<Nav.Link href="#home" className='bd-highlight' style={{backgroundColor: "white"}}>MENU</Nav.Link>*/}
          <Divider orientation="vertical" style={{paddingLeft: "2px", paddingRight: "2px"}}/>
          {/*<Nav.Item ><SideCar/></Nav.Item> //USE FOR TOGGLE SIDE-DRAWER W/ BUTTON IN NAVBAR */}
          {/*<NavDropdown title="Dropdown" style={{backgroundColor: "white"}} id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
          </NavDropdown>*/}
          {props.authenticated ? (<Button onClick={props.logOut}>Logout</Button>) : null}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Top;
