import React, { Component } from 'react';
import axios from 'axios';
import { Provider, connect } from 'react-redux';
import Top from './components/NAVBAR/Top';
import Deed from './components/DATA/Deed';
import SideCar from './components/SIDEBAR/Sidebar';
import { makeStyles } from '@material-ui/core/styles';
import BoxImage from './components/IMAGE/Image';
import DataEntryPoint from './components/DATA/Init';
import Header from './components/HEADER/Header';
import Footer from './components/FOOTER/Footer';
import { library } from '@fortawesome/fontawesome-svg-core';
import Typography from '@material-ui/core/Typography';
import Authentication from './components/AUTH/Auth';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faGooglePlusG, faFacebook, faNode, faGoogle, faNpm, faPython, faReact } from '@fortawesome/free-brands-svg-icons';
import { Button } from 'react-bootstrap';

library.add(fab, faGooglePlusG, faFacebook, faNode, faGoogle, faNpm, faPython, faReact)
let x = 0;
class App extends Component {

  constructor(props) {
    console.log('No. 3') // #3
    super(props)
    this.auth = this.props.fire
    this.goo = this.props.goopro
    this.state = {
      styleMain: {
        paddingLeft: "2.75%", //SideCar Component ==> 20%
        paddingRight: "2.75%", //SideCar Component ==> 7.5%
        paddingTop: "0.0%"
      }
    }
  }

  componentDidMount = async () => {
    console.log('No. 5') // #5
    await this.auth.onAuthStateChanged((user) => {
      if(user !== null) {
        user.getIdTokenResult().then(idTokenResult => {
          if (idTokenResult) {
            const token = idTokenResult['token'];
            this.setState({token: token, loggedIn: true})
            console.log('async if No. 17') // #17
          }
        })} else {
          this.setState((state) => {return({loggedIn: false})})
          console.log('async else No. 10') // #10
        }
      })
      console.log('No. 8') // #8
    }

  signInPrompt = () => this.auth.signInWithPopup(this.goo).then((user)=> {
    const newUser = user.additionalUserInfo.isNewUser;
    if (newUser) {
      this.setState({newUser: newUser})
    }
  })

  signOut = () => this.auth.signOut();

  render() {

    console.log('No. 4 & 9 & 11') // #4 & #9 & #11
    let token = this.state.token;
    let newUser = this.state.newUser;
    let loggedIn = this.state.loggedIn;
    x++;
    if (x >= 2) {
      console.log('––––––––––––––––––––––––––––––––––––––––––––');
    }
    return (
        <div id="container" className="inner-container" style={{color: 'white', backgroundColor: 'white', minHeight: "100vh", position: "relative"}}>
          <Header absolute color="transparent" authenticated={loggedIn} logOut={this.signOut}></Header>
          <br />
          { loggedIn && token ?
            (<DataEntryPoint style={this.state.styleMain} token={token} newUser={newUser}/>)
            :
            (<Authentication authenticated={loggedIn} logIn={this.signInPrompt} create={this.registerNewUser}/>)}
            <br/>
          <Footer />
        </div>
    )
    //No fire
  }
}

export default App;
