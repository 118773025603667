import React, { Component, PureComponent, lazy, Suspense } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import ButtonToolBar from '../UTILS/ButtonToolBar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import {ButtonToolbar, ButtonGroup} from 'react-bootstrap';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

class Deed extends PureComponent {

  state = {
    edit: false,
    TextBlock: "",
  }

  constructor(props) {
    console.log('No. 14') // #14
    super(props)
    this.token = props.token;
    this.Ref = React.createRef();
  }

  useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        margin: theme.spacing(2),
        padding: "0.50%",
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    }
  }));

  updateDocument = () => {
    console.log('Deed No. 2')
    this.setState((state) => {return({edit: !this.state.edit})})

    if(this.state.edit) {
      //Event is arbitrary until passed to an event property of DOM element or a Component.
      //Passing a function invoking `event` grants access to React's synthetic event object.
      //A particular event is assigned by passing the function to an event handler property.
      //Hence, passing this function to onClick will then set the eventlistener to listen for
      // a mouseclick on the component that actually fires in response to a mouse click on it.

      //Compare text content for change.
      //const currentProp = this.props.textBlock;
      //console.log(currentProp);

      const changedProp = this.Ref.current.textContent;
      //console.log(new Object(this.Ref.current));

      //if(currentProp !== changedProp) { //Limits edit saves IFF content is different. Too restrictive in development.
      let updateAPI = `https://www.spygot.co/updateDeed/${this.props.deed}/${this.props.page}/${this.props.box}/${changedProp}`

      //let updateAPI = `http://www.localhost:8088/updateDeed/${this.props.deed}/${this.props.page}/${this.props.box}/${changedProp}`

      axios({
          baseURL: updateAPI,
          method: 'get',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + this.token,
            'Content-Type': 'application/json'
          }
          }).then((response) => {
            if(response.statusText === 'OK') {
              console.log(`MongoDB document ${this.props.deed} has been updated successfully.`)
            }}).catch(err => console.log(err.message))
    }
}

  render() {
    console.log('No. 15 & 19 & 22 &24') // #15 & #19 & #22 &#24
    const classes = this.useStyles;
    const props = this.props;
  return(
    <Card   style={{backgroundColor: '#262626', padding: "1.0%"}}>
    <Box bgcolor="dodgerblue" color="white" fontSize={26} fontFamily="Monospace" fontWeight="fontWeightBold" p={1}>
      DEED TEXT BLOCK
    </Box>
    <Divider />
    <Box suppressContentEditableWarning style={{ overflowY: "auto",
                  height: '42.5vh',
                  whiteSpace: 'wrap',
                  fontSize: "2.00vw",
                  padding: "2%",
                  color: "black"
                }}
           bgcolor="background.paper"
           ref={this.Ref}
           contentEditable={this.state.edit}>{props.textBlock}
    </Box>
    <Divider />
<ButtonToolBar editable={this.state.edit} handler={this.updateDocument} clicker={props.clicker} />
</Card>
    )
  }
}

export default Deed;

/*
Previously worked and designed to be own separate component. However, later updates code broke.

<ButtonToolBar editable={this.state.edit} handler={this.updateDocument} clicker={props.clicker} />

*/
/*<Divider style={{paddingTop: "3%", paddingBottom: "3%"}}/>

  <ButtonToolbar component="div" className={classes.root}>
    <ButtonGroup className="mr-2" aria-label="First Group">
      <Button id="previous" onClick={props.prev} variant="contained" className={classes.button} startIcon={<FaArrowAltCircleLeft/>}>Next</Button>
      <Button id="next" onClick={props.clicker} variant="contained" className={classes.button} startIcon={<FaArrowAltCircleRight/>}>Next</Button>
      <Button variant="contained" className={classes.button} startIcon={<EditIcon />} color="primary" aria-label="edit" onClick={this.updateDocument}>{!props.editable ? 'EDIT' : 'SAVE'}</Button>
      <Button variant="contained" color="secondary" className={classes.button} startIcon={<DeleteIcon />}>Delete</Button>
    </ButtonGroup>
  </ButtonToolbar>*/

/*
<Typography component="div" style={{
                    backgroundColor: '#262626',
                    height: '45vh',
                    padding: "1.0%"
                  }}>
<Box suppressContentEditableWarning style={{ overflowY: "auto",
              height: '42.5vh',
              whiteSpace: 'wrap',
              fontSize: "2.00vw",
              padding: "2%",
              color: "black"
            }}
       bgcolor="background.paper"
       ref={this.Ref}
       contentEditable={this.state.edit}>{props.textBlock}
</Box>
<br/>
</Typography>
*/
