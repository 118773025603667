import React, {Component} from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import IconButton from '@material-ui/core/IconButton';
// @material-ui/icons
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
// Components
import Button from "./core/CustomButtons/Button.js";
import CardHeader from "./core/Card/CardHeader.js";
// Helpers
import clsx from 'clsx';
// Styles
import styles from "./core/CustomInput/customInputStyle.js";
import { container } from "../../assets/material-kit-react.js"

// Component styling
const useStyles = makeStyles(styles);

const materialStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  },
}));

// Functional Component for User Sign-In
export default function SignIn(props) {
  const appStyles = useStyles();
  const classes = materialStyles();
  const [values, setValues] = React.useState({
    isSignedIn: props.authenticated,
    credential: ""
  });

  const googleLogin = (props) => {
    props.logIn().then(
      (result) => {
        var token = result.credential.accessToken // This gives you a Google Access Token.
        var user = result.user // The signed-in user info.
        setValues({...values, ['credential']: token})
        console.log({...values})
      }
    )
  }

  return(

    <div>

      <CardHeader color="primary" style={{marginBottom: "3%"}}>

        <h4 style={{color: "white"}}>Login</h4>

        <div>

          <Button justIcon color="transparent" onClick={props.logIn}>
            <FontAwesomeIcon icon={['fab', 'google']} style={{color: "white"}}/>
          </Button>

          <Button justIcon color="transparent" onClick={e => e.preventDefault()}>
            <FacebookIcon style={{color: "white"}}/>
          </Button>

          <Button justIcon color="transparent" onClick={e => e.preventDefault()}>
            <GitHubIcon style={{color: "white"}}/>
          </Button>

          <Button justIcon color="transparent" onClick={e => e.preventDefault()}>
            <TwitterIcon style={{color: "white"}}/>
          </Button>

          <Button justIcon color="transparent" onClick={e => e.preventDefault()}>
            <LinkedInIcon style={{color: "white"}}/>
          </Button>

        </div>

      </CardHeader>

    </div>
  )
}
