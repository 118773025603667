import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';

//import "./scss/material-kit-react.scss";
import App from './App';
import { Route, BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

// Import default Firebase components
import { firebaseApp, googleProvider } from './firebase-config';

firebaseApp.auth().setPersistence(firebaseApp.firebase_.auth.Auth.Persistence.NONE)

googleProvider.addScope('profile')
googleProvider.addScope('email')
googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
googleProvider.setCustomParameters({
  'login_hint': 'user@example.com',
  'prompt': 'select_account'
});

class Root extends Component {

  constructor(props) {
    console.log('No. 1') // #1
    super(props)
    this.auth = firebaseApp.auth();
    this.goo = googleProvider;
  }

  setUserInfo = (user) => {

    const displayName = user.displayName;
    const email = user.email;
    const emailVerified = user.emailVerified;
    const photoURL = user.photoURL;
    const isAnonymous = user.isAnonymous;
    const uid = user.uid;
    const providerData = user.providerData;

    this.setState((state) => {//
      return({
        loggedIn: !state.loggedIn,
        User: {
          UserName: displayName,
          Email: email,
          Verified: emailVerified,
          UserID: uid,
        }
      })
    })
  }

  componentDidMount = () => {
    console.log('No. 6') // #6
  }

  render() {
    console.log('No. 2') // #2
    return (
      <Route exact path="/" component={() => <App fire={this.auth} goopro={this.goo}/>} />
    )
  }
}

ReactDOM.render(
                <Router>
                      <Root />
                </Router>

                ,document.getElementById('root')
              );

console.log('No. 7') // #7

serviceWorker.unregister();
