import React, { Component, PureComponent, Suspense } from 'react';
import axios from 'axios';
import { Container, Col, Row } from 'react-bootstrap';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCube, faFileAlt, faScroll } from '@fortawesome/free-solid-svg-icons';
import Deed from './Deed';
import Image from 'react-bootstrap/Image'
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Labels from '../LABEL/Label';
//import ButtonToolBar from '../UTILS/ButtonToolBar'

class DataEntryPoint extends Component {

  state = {}

  constructor(props) {
    console.log('No. 12') // #12
    super(props)
    this.token = props.token;
    this.getNextDeed = this.getNextDeed.bind(this);
    this.pageIterator = this.pageIterator.bind(this);
    this.getNextDeed();
  }

  componentDidMount = () => {
    console.log('No. 16') // #16
    //Somehow executing while App.js is still processing the authentication.
    /*
    Below code does work but, causes latency.
    if(this.state.deed == null) {
      this.getNextDeed();
    } else {
      return
    }*/
    //this.getNextDeed();
  }

  //--------------------------------------------------------------------------------------------------------------//

  getNextDeed = async () => {

    let data;

    await axios({
      //baseURL: 'http://www.localhost:8088/deed/',
      baseURL: 'https://www.spygot.co/deed',
      method: 'get',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + this.token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }
    })

    .then((response) => {
      if(response.statusText === 'OK') {

        data = response.data

      }
    })

    .then(() => {

      this.setState((state) => {return({deedNo: data[1], deed: data[2], pages: data[0]})})

    })

    .catch(err => console.log(err.message))

    this.pages = this.pageIterator(this.state.pages); // #20

    try {

      this.pages.next()

    } catch (e) {

      console.log(e.message);

    }

    this.getImageURL()

  }


  //--------------------------------------------------------------------------------------------------------------/
  //INTERESTING: asychronous function will properly set imageurl onload whereas the synchronous version
  //outputs undefined in the console.

  getImageURL = async () => {
    await axios({
      //baseURL: `http://www.localhost:8088/image/${this.state.deedNo}/${this.state.Page}/${this.state.Box}`,
      baseURL: `https://www.spygot.co/image/${this.state.deedNo}/${this.state.Page}/${this.state.Box}`,
      method: 'get',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': '*',
      }
    }).then((response) => {
      if(response.statusText === 'OK') {
        this.setState((state) => {
          return({imageURL: response.data[0]})
        })
      }
    })
  }

  //--------------------------------------------------------------------------------------------------------------//

  *pageIterator (A) { // #20
    let TEXTBLOCK;
    for (let B of A) {
      for (let C of Object.keys(this.state.deed[B].Blocks)) {
        let text = this.state.deed[B].Blocks[C].Text;
        if(Array.isArray(text) && text[0].length > 0) {
          TEXTBLOCK = text.join(' ') //
          console.log(`${TEXTBLOCK} first condition No. 20`);
        } else if (Array.isArray(text)) {
          TEXTBLOCK = text[0];
          console.log(`${TEXTBLOCK} second condition No. 20`);
        } else {
          TEXTBLOCK = text;
          console.log(`${TEXTBLOCK} third condition No. 20`);
        }
        yield this.setState((state) => {return({Box: C, Page: B, BlockText: TEXTBLOCK})})
        }
      }
    }

  //--------------------------------------------------------------------------------------------------------------//

  showNext = async () => {
    if(!this.pages) {
      return
    } else {
      //Without `await` keyword in front the call to the generator next() method, the imageurl and textblock are not synchronized. Including await, however, keeps the two in parllel. Interestingly, it is not true vice versa.
      const following = await this.pages.next();
      this.getImageURL();
      if (following.done) {
        this.getNextDeed()
      }
    }
  }

  //--------------------------------------------------------------------------------------------------------------//

  useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    styleIcon: {
      fontSize: "8.00rem",
      color: "#ff3366",
      position: "relative",
      top: "8px",
      left: "5px",
    },
    cardcontainer: {
      marginBottom: "5%",
      background: theme.palette.primary.main,
      color: theme.paletter.primary.main
    }
  }));

  //--------------------------------------------------------------------------------------------------------------//

  render() {
    console.log('No. 13 & 18 & 21 &23') // #13 & #18 & #21 &#23
      const classes = this.useStyles;
      const props = this.props;
      return(
        <Typography component="div" style={props.style}>
          <Box bgcolor="#f50057" color="secondary.contrastText" fontSize={26} fontFamily="Monospace" fontWeight="fontWeightBold" p={1}>
            DASHBOARD
          </Box>
          <Divider />

          <Card>
            <Grid container spacing={3} style={{padding: ".5%", color: "black"}}>
                <Grid item style={{padding: "8px"}}>
                  <FontAwesomeIcon icon={faBook} style={classes.styleIcon} color="#f50057" size="2x"/>
                </Grid>
                <Grid item style={{padding: "8px"}}>
                  <Box textAlign="center" fontWeight="fontWeightBold">{this.state.deedNo}</Box>
                </Grid>
                <Grid item style={{padding: "8px"}}>
                  <FontAwesomeIcon icon={faFileAlt} style={classes.styleIcon} color="#f50057" size="2x"/>
                </Grid>
                <Grid item style={{padding: "8px"}}>
                  <Box textAlign="center" fontWeight="fontWeightBold">{this.state.Page}</Box>
                </Grid>
                <Grid item style={{padding: "8px"}}>
                  <FontAwesomeIcon icon={faCube} style={classes.styleIcon} color="#f50057" size="2x"/>
                </Grid>
                <Grid item style={{padding: "8px"}}>
                  <Box textAlign="center" fontWeight="fontWeightBold">{this.state.Box}</Box>
                </Grid>
            </Grid>
          </Card>

          <Divider style={{paddingTop: "1%", paddingBottom: "1%"}}/>

            {/*<Card>

              <Box bgcolor="dodgerblue" color="white" fontSize={26} fontFamily="Monospace" fontWeight="fontWeightBold" p={1}>
                DEED TEXT BLOCK
              </Box>
              <Deed token={this.token} textBlock={this.state.BlockText} deed={this.state.deedNo} page={this.state.Page} box={this.state.Box} style={{margin: "2.0%"}}/>
            </Card>
              */}

                <Deed token={this.token} textBlock={this.state.BlockText} deed={this.state.deedNo} page={this.state.Page} box={this.state.Box} clicker={this.getNextDeed} style={{margin: "2.0%"}}/>

          <Divider style={{paddingTop: "1%", paddingBottom: "1%"}}/>

          <Card className={classes.cardcontainer}>
            <Box bgcolor="dodgerblue" color="white" fontSize={26} fontFamily="Monospace" fontWeight="fontWeightBold" p={1}>
              SOURCE DOCUMENT IMAGE
            </Box>
            <Image src={this.state.imageURL} thumbnail style={{marginTop: "1.0%", marginBottom: "2.0%", marginRight: "1%", marginLeft: "1%"}}/>
          </Card>

        </Typography>
      )
    }
}

export default DataEntryPoint;

/*
<Divider
  variant="fullWidth"
  style={{
          backgroundColor: "dodgerblue",
          paddingTop: "1%",
          paddingBottom: "1%",
          borderTopStyle: "solid",
          borderTopWidth: "3px",
          borderTopColor: "white",
          borderBottomStyle: "solid",
          borderBottomWidth: "3px",
          borderBottomColor: "white"
        }}
  />
*/
