// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";

//import "firebase/firestore"
//import "firebase/storage"
//import "firebase/performance"
//import "firebase/remote-config"
//import "firebase/database"
//import "firebase/functions"
//import "firebase/analytics"
//import "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyBzlZxpVlJxTqy9Yzkzh5KJJUINMZ2XIF8",
  authDomain: "fir-265103.firebaseapp.com",
  databaseURL: "https://fir-265103.firebaseio.com",
  projectId: "firebase-265103",
  storageBucket: "firebase-265103.appspot.com",
  messagingSenderId: "2523554158",
  appId: "1:2523554158:web:6a210a6cfa281cdcf2617f",
  measurementId: "G-NMYLZXENPG"
}

// Initialize Firebase with a "default" Firebase project
export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
