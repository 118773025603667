import React, {Component} from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';

// bootstrap components
import Accordion from 'react-bootstrap/Accordion';

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons';
import { faCaretSquareDown } from '@fortawesome/free-solid-svg-icons';
import LockIcon from '@material-ui/icons/Lock';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components
import GridContainer from "./core/Grid/GridContainer.js";
import GridItem from "./core/Grid/GridItem.js";
import Button from "./core/CustomButtons/Button.js";
import Card from "./core/Card/Card.js";
import CardBody from "./core/Card/CardBody.js";
import CardHeader from "./core/Card/CardHeader.js";
import CardFooter from "./core/Card/CardFooter.js";
import CustomInput from "./core/CustomInput/CustomInput.js";
import SignUpInput from './UserRegistration';
import SignIn from './UserSignIn';

// Helpers
import clsx from 'clsx';

// styles
import { container } from "../../assets/material-kit-react.js"

// Component styling
const signupPageStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: "#FFFFFF",
    paddingBottom: "200px"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF"
    },
    "& footer": {
      position: "relative",
      bottom: "0",
      width: "100%"
    }
  },
  form: {
    margin: "0",
    minWidth: "30%",
    maxWidth: "40%"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "5%",
    padding: "20px 0",
    marginBottom: "1%",
    backgroundColor: "#f50057"
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "white"
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center",
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",

  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: "#495057"
  },
  card: {
    transform: "translate(0%, 15%)",
    maxWidth: "40%",
    minWidth: "20%"
  }
};
const expansionStyle = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

// React component
class Authentication extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cardHidden: true,
      redirect: false,
      expanded: false,
      image: "https://storage.googleapis.com/z_static/Images/Zidualz_Logo.png",
    }
  }

  expansionHandler = () => {
    this.setState((state) => {
      return({expanded: !state.expanded})
    })
  }

  useStyles = makeStyles(signupPageStyle);

  render() {

    const classes = this.useStyles;
    const expStyle = expansionStyle;
    const props = this.props;

    return(
            <div>

              <div>

                <div>

                  <GridContainer justify="center">

                    <GridItem xs={12} sm={12} md={4}>

                      <Card className={classes.card}>

                        <form className={classes.form} style={{
                          marginTop: "5%",
                          position: "relative",
                          verticalAlign: "middle",
                          align: "center",
                          top: "50%",
                          left: "0%",
                        }}>

                        <SignIn authenticated={props.authenticated} fire={props.fire} logIn={props.logIn}/>

                        <Button
                          className={clsx(expStyle)}
                          onClick={this.expansionHandler}
                          aria-expanded={this.state.expanded}
                          aria-label="click to expand"
                          variant="contained"
                          color="primary"
                          disableFocusRipple={true}
                          disableRipple={true}
                          startIcon={
                            <FontAwesomeIcon icon={faCaretSquareDown} style={{
                              color: "white"
                            }}/>
                          }
                          style={{marginLeft: "5%"}}
                          >
                          Register
                        </Button>

                        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                          <CardBody>
                            <SignUpInput authenticated={props.authenticated} create={props.create}/>
                          </CardBody>
                        </Collapse>
                        </form>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            </div>
          )
        }
      }

/*const mapStateToProps = state => ({
  currentUser: state.user.currentUser
});

export default connect(mapStateToProps)(Authentication);*/

export { Authentication as default};
