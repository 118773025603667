import React, {Component} from 'react';
import clsx from 'clsx';
import axios from 'axios';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';

// @material-ui/icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Face from '@material-ui/icons/Face';
import ContactMail from '@material-ui/icons/ContactMail';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Fingerprint from '@material-ui/icons/Fingerprint';
import PhoneIphone from '@material-ui/icons/PhoneIphone';

// Styles
import styles from "./core/CustomInput/customInputStyle.js";
import buttonStyle from './core/CustomButtons/buttonStyle';

const useStyles = makeStyles(styles);
const materialStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 200,
  },
}));
const buttStyle = makeStyles(buttonStyle);

export default function SignUpInput(props) {

  const appStyles = useStyles();
  const classes = materialStyles();

  const [values, setValues] = React.useState({
    name: '',
    password: '',
    email: '',
    username: '',
    phone: '',
    showPassword: false,
  });

  const registerNewUser = (parameters) => {
    const {name, password, email, username, phone} = parameters;
    const query = `/registerNewUser/${username}/${password}/${email}/${name}/${phone}`;
    axios.post('https://www.spygot.co/' + query).then((response) => {
      console.log('Created new user.', response)
    }).catch(err => console.log(err))
  }

  const inputChangeHandler = prop => event => {
    return null
  }

  const passwordHandler = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
    console.log(values.password);
  };

  const nameHandler = prop => event => {
    setValues({ ...values, [prop]: event.target.value})
    console.log(values.name)
  }

  const emailHandler = prop => event => {
    setValues({ ...values, [prop]: event.target.value})
    console.log(values.email)
  }

  const userNameHandler = prop => event => {
    setValues({ ...values, [prop]: event.target.value})
    console.log(values.username)
  }

  const phoneHandler = prop => event => {
    setValues({ ...values, [prop]: event.target.value})
    console.log(values.phone)
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const onSubmit = event => {
    if(values) {
      registerNewUser({...values})
    }
    event.preventDefault();
  }

  return(
    <form>
      <div>
        <FormControl margin="dense">
          <InputLabel htmlFor="standard-adornment-name">Name</InputLabel>

          <Input id="standard-adornment-name"
                 value={values.name}
                 type="text"
                 onChange={nameHandler('name')}
                 endAdornment=
                 {
                   <InputAdornment position="end">
                     <Face aria-label="name icon" />
                   </InputAdornment>
                 }
               />
        </FormControl>
      </div>
      <div>
        <FormControl margin="dense">
          <InputLabel htmlFor="standard-adornment-emai">Email</InputLabel>

          <Input id="standard-adornment-email"
                 value={values.email}
                 onChange={emailHandler('email')}
                 endAdornment=
                 {
                   <InputAdornment position="end">
                     <ContactMail aria-label="email icon" />
                   </InputAdornment>
                 }
               />
        </FormControl>
      </div>
      <div>
        <FormControl margin="dense">
          <InputLabel htmlFor="standard-adornment-name">User Name</InputLabel>

          <Input id="standard-adornment-name"
                 value={values.username}
                 type="text"
                 onChange={userNameHandler('username')}
                 endAdornment=
                 {
                   <InputAdornment position="end">
                     <Fingerprint aria-label="username icon" />
                   </InputAdornment>
                 }
               />
        </FormControl>
      </div>
      <div>
        <FormControl margin="dense">
          <InputLabel htmlFor="standard-adornment-name">Phone Number</InputLabel>

          <Input id="standard-adornment-name"
                 value={values.phone}
                 type="tel"
                 onChange={phoneHandler('phone')}
                 endAdornment=
                 {
                   <InputAdornment position="end">
                     <PhoneIphone aria-label="phone icon" />
                   </InputAdornment>
                 }
               />
        </FormControl>
      </div>
      <div>
        <FormControl margin="dense">
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input id="standard-adornment-password"
                 type={values.showPassword ? 'text' : 'password'}
                 value={values.password}
                 onChange={passwordHandler('password')}
                 endAdornment=
                 {
                   <InputAdornment position="end">
                     <IconButton
                       aria-label="toggle password visibility"
                       onClick={handleClickShowPassword}
                       onMouseDown={handleMouseDownPassword}
                     >
                       {
                         values.showPassword ? <Visibility /> : <VisibilityOff />
                        }
                      </IconButton>
                    </InputAdornment>
                }
              />
        </FormControl>
      </div>
      <div>
        <FormControl margin="normal">
          <ToggleButton
            variant="contained"
            type="submit"
            onClick={onSubmit}
            className={buttStyle}
            endIcon={<DoneOutlineIcon/>}
            style={{textAlign: "center", position: "relative", transform: "translate(40%, 0%)", color: "#f50057"}}
            >
              Submit
            </ToggleButton>
        </FormControl>
      </div>
    </form>
  )
}
